import React from 'react';

const Faq = () => (
  <section id="faqs">
      <div className="container">
          <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                  <div className="section-head-tag">FAQs</div>
                  <h2 className="section-head-title">Frequently Asked Questions</h2>
                  <p className="section-head-body">Answers to common questions to help you get started.</p>
              </div>
          </div>
          <div className="row">
              <div className="col-lg-8 col-md-12 faqs-container offset-lg-2">
                <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq1">
                            What is an NFT?
                          </h5>
                      </div>
                      <div className="collapse" id="faq1">
                          <div className="faq-body">
                              <p>An NFT is a non-fungible token. It is a unit of data stored on a blockchain that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of digital files.</p>
                          </div>
                      </div>
                  </div> 
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq2">
                            What is <i>The JPEG Gallery</i>?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq2">
                          <div className="faq-body">
                              <p><i>The JPEG Gallery</i> is a digital art collection made up of <b>6,969 unique NFTs</b> on the Ethereum blockchain. Each NFT is drawn and given a unique name by a community member.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq3">
                            How does it work?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq3">
                          <div className="faq-body">
                              <p>Community members can create and submit custom artwork to the collection. Once their image has been submitted to the gallery, the image will be assigned to an NFT with randomly generated color traits.</p>
                          </div>
                      </div>
                  </div>  
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq4">
                            How do I participate in <i>The JPEG Gallery</i>?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq4">
                          <div className="faq-body">
                              <p>To participate, you must first <b>purchase a Canvas NFT from the <a href="/shop">Shop</a> tab</b>. This Canvas NFT gives you access to submit one piece of art to the gallery. Once you have a Canvas NFT, you can <b>visit the <a href="/create">Create</a> tab to make your submission</b>.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq5">
                            How do I get a NFT?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq5">
                          <div className="faq-body">
                              <p>Canvas NFTs will be available to mint directly from the <b><a href="/shop">Shop</a></b> tab on our website on <b>February 10, 2022 1:00 PM PST</b>. To purchase a Canvas, simply connect your wallet to our website via Metamask, and select the number you wish to mint. If the NFTs are sold out, you can purchase one off the secondary market via OpenSea.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq6">
                            How much does it cost to mint an NFT?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq6">
                          <div className="faq-body">
                              <p><b>0.069 ETH</b> + Gas fees.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq7">
                            How many NFTs can I purchase at once?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq7">
                          <div className="faq-body">
                              <p>Users can mint up to <b>10</b> Canvas NFTs at once during the public sale.</p>
                              <p>Whitelisted users can mint <b>1</b> Canvas NFT during the pre-sale.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq8">
                            When will NFTs be available to mint?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq8">
                          <div className="faq-body">
                              <p>A <b>pre-sale</b> will be held on <b>February 10, 2022 11:00 AM-12:00 PM PST</b> for the minting of <b>420 NFTs</b> (whitelisted users only).</p>
                              <p>A <b>public sale</b> will be held on <b>February 10, 2022 1:00 PM PST</b> for the minting of <b>6,449 NFTs</b>.</p>
                              <p>Visit the <b><a href="/shop">Shop</a></b> tab to mint a Canvas NFT on these dates.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq9">
                            When will <i>The JPEG Gallery</i> be open for submissions?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq9">
                          <div className="faq-body">
                              <p>Gallery submissions will be available on <b>February 10, 2022 11:00 AM PST</b>. To make a submission, visit the <b><a href="/create">Create</a></b> tab.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq10">
                            When will <i>The JPEG Gallery</i> be closed for submissions?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq10">
                          <div className="faq-body">
                            <p><i>The JPEG Gallery</i> will be open for submissions <b>indefinitely</b>.</p>
                            <p><i>Note: To discourage Canvas NFT squatting, a closing date for submissions will be decided at a later date. The date will be decided by the community via a vote, but will be no earlier than <b>February 10, 2023</b></i>.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq11">
                            Why can I only use 4 colors to create my image?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq11">
                          <div className="faq-body">
                              <p><b>4 grayscale colors</b> are provided to you when creating a submission from the <b><a href="/create">Create</a></b> tab. Upon making a submission to the gallery, <b>randomly generated color traits will be applied to your image</b>, replacing the original grayscale colors.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq12">
                            How many different traits are there?
                          </h5>
                      </div>
                      <div className="collapse" id="faq12">
                          <div className="faq-body">
                              <p>Upon making a submission to the gallery, your NFT will gain <b>3 different traits</b>. These traits include:</p>
                              <ol>
                                    <li>
                                    <b>Hue</b>: One of 12 different color hues <i>(i.e. Red, Orange, Yellow, Green, etc.)</i>
                                    </li>
                                    <li>
                                    <b>Harmony</b>: One of 3 different color harmonies <i>(i.e. Default, Analogous, Complementary)</i>
                                    </li>
                                    <li>
                                    <b>Style</b>: One of 4 different color styles <i>(i.e. Default, Pastel, Dark, Shiny)</i>
                                    </li>
                                </ol>
                                <p>
                                Each of these traits are randomized and will be applied to the original user-submitted image.
                                
                                For more details on traits, visit our documentation.
                                </p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq13">
                            What type of tokens are The JPEG Gallery NFTs?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq13">
                          <div className="faq-body">
                              <p>Each NFT is an <b>ERC-721</b> token.</p>
                          </div>
                      </div>
                  </div>
                  <div className="faq">
                      <div className="faq-head">
                          <h5 className="mb-0" data-toggle="collapse" data-target="#faq14">
                            Where is NFT metadata stored?
                          </h5>
                      </div>                      
                      <div className="collapse" id="faq14">
                          <div className="faq-body">
                              <p>Because of the user-generated nature of the project, the metadata associated with each NFT will <b>initially be stored in a database and served by our back-end metadata server</b>, allowing for better community support while the submission window is open.</p>
                              <p>Upon the closing date for submissions to the gallery, <b>all metadata and images will be migrated to IPFS</b>.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
)

export default Faq;