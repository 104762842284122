import React from 'react';
import Cover from './Cover';
import About from './About';

const Home = () => (
    <div>
        <Cover />
        <About />
    </div>
)

export default Home;
