import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Shop from './Shop'
import Create from './Create'
import Faq from './Faq'

const DOCS_URL = 'https://jpeggallery.notion.site/jpeggallery/The-JPEG-Gallery-Docs-fade35da78224dc78ec22e76b5473e7d'

const Main = () => (
    <main>
        <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/shop' component={Shop}/>
        <Route path='/create' component={Create}/>
        <Route path='/faq' component={Faq}/>
        <Route path='/docs' component={() => {
            window.location.href = DOCS_URL;
            return null;
            }}/>
        </Switch>
    </main>
)

export default Main