import Slider from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';

const StrokeSlider = styled(Slider)(() => ({
  color: '#fe3030',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha('#fe3030', 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha('#fe3030', 0.16)}`,
    },
  },
}));

export default StrokeSlider;





