import React, {useEffect, useState} from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { formatEther, commify } from '@ethersproject/units'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Timer from './Timer';

import { useEagerConnect, useInactiveListener } from '../hooks'
import { injected } from '../connectors'

import JpegGallery from '../abis/JpegGallery.json';

const Shop = () => {

  const context = useWeb3React()
  const { connector, library, chainId, account, activate, deactivate, error } = context

  const [contract, setContract] = useState()
  const [paused, setPaused] = useState()
  const [saleIsOpen, setSaleIsOpen] = useState()
  const [whitelistIsOpen, setWhitelistIsOpen] = useState()
  const [status, setStatus] = useState('Closed')
  const [price, setPrice] = useState('0.069')
  const [remaining, setRemaining] = useState('6969')
  const [maxSupply, setMaxSupply] = useState(6969)
  const [purchaseLimit, setPurchaseLimit] = useState(1)
  const [mintAmount, setMintAmount] = useState(1)
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertColor, setAlertColor] = useState("#161d2e");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const abi = JpegGallery.abi
  const SALE_TIME = 1644526800; // Wed Feb 02 2022 22:09:00 GMT-0800 (Pacific Standard Time) (1pm)

  useEffect(() => {
    let contract = null;
    if (chainId && library) {
        const address = JpegGallery.networks[chainId]?.address
        const provider = account ? library.getSigner(account).connectUnchecked() : library;
        if (!address) {
          console.error(`Unsupported network (Chain ID: ${chainId})`)
        } else {
          contract = new Contract(address, abi, provider)
        }
        
    }
    setContract(contract)
  }, [library, chainId, account])

  useEffect(() => {
      getInfo()
  }, [contract, paused, price, remaining, saleIsOpen, whitelistIsOpen])

  const getInfo = async () => {
    if (contract) {
      // Get Price
      const price = await contract.PRICE()
      setPrice(formatEther(price))
      // Get Remaining
      const maxSupply = await contract.MAX_SUPPLY()
      const totalSupply = await contract.totalSupply()
      setMaxSupply(commify(maxSupply.toNumber()))
      const remaining = maxSupply.toNumber() - totalSupply.toNumber();
      setRemaining(commify(maxSupply.toNumber() - totalSupply.toNumber()))
      // Get Status
      const isPaused = await contract.paused()
      const saleIsOpen = await contract.saleIsOpen();
      const whitelistIsOpen = await contract.whitelistIsOpen();
      setPaused(isPaused)
      setSaleIsOpen(saleIsOpen)
      setWhitelistIsOpen(whitelistIsOpen)
      const status = (isPaused || (!whitelistIsOpen && !saleIsOpen)) ? 'Closed' : remaining === 0 ? 'Sold Out' : 'Open'
      setStatus(status)
      const purchaseLimit = await contract.PURCHASE_LIMIT()
      setPurchaseLimit(purchaseLimit.toNumber())
    }
  }

  const mint = async () => {
    setOpenBackdrop(true);
    let alertMessage = `Processing transaction: Mint Canvas (x${mintAmount}).`;
    let alertColor = "#161d2e";
    let alertSeverity = "info";
    try {
      if (contract && account) {
        const currentPrice = await contract.PRICE()
        if (whitelistIsOpen) {
          await contract.whitelistMint(mintAmount, {from: account, value: currentPrice.mul(mintAmount)})
        } else {
          await contract.mint(account, mintAmount, {from: account, value: currentPrice.mul(mintAmount)})
        }
      }
    } catch (e) {
      alertMessage = mintAmount > purchaseLimit ? 'Purchase limit (10) exceeded.' :
        mintAmount < 1 ? 'Amount must be greater than 0.' : `Transaction rejected.`;
      alertColor = "#fe3030";
      alertSeverity = "error";
    }
    setAlertMessage(alertMessage);
    setAlertColor(alertColor);
    setAlertSeverity(alertSeverity);
    setOpenAlert(true);
    setOpenBackdrop(false);
  }

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const connected = connector === injected

  return (<section id="shop-section">
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openBackdrop}
    >
    <CircularProgress color="inherit" sx={{ color: '#fe3030',}}/>
    </Backdrop>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 section-head text-center">
          <div className="shop-container">
            <div className="title-header shop-header">
                <div className="shop-title">Shop</div>
                {(!saleIsOpen) && (<Timer seconds={SALE_TIME - Math.round(new Date().getTime() / 1000)}/>)}
            </div>
            <div className="shop-main">
              <div className="shop-preview">
                  <div className="shop-preview-content">
                    <img className="shop-img" src="assets/images/canvas.png" alt="canvas" height="300" />
                  </div>
                  <div className="shop-footer">
                      <p className="mint-body">JPEG Gallery Canvas</p>
                      {!(connected && account && !error) && (
                        <button className="btn-main btn-mint" onClick={!connected ? () => {
                          setActivatingConnector(injected)
                          activate(injected)
                      }: error ? () => {
                          deactivate(injected)
                      } : ()=>{}}>Connect Wallet</button>
                      )}
                      {(connected && !error) && (
                        <div className="mint-action-content">
                          <input disabled={paused || (chainId !== 1) || (!saleIsOpen && !whitelistIsOpen)} className="mint-amount" type="number" min="1" max={purchaseLimit} placeholder="Amount" value={mintAmount} onInput={e => setMintAmount(e.target.value)}></input>
                          <button disabled={paused || (chainId !== 1) || (!saleIsOpen && !whitelistIsOpen)} className="btn-main btn-mint" onClick={connected ? ()=>{mint()} : ()=>{}}>Mint</button>
                        </div>
                      )}
                      <div className="status-box">
                          <div className="status">
                              <span className='status-content'><span className={
                                status === 'Closed' ? 'sale-status-closed' :
                                status === 'Sold Out' ? 'sale-status-sold-out' : 'sale-status-open'
                            }></span>Status</span>
                              <h3>{status}</h3>
                          </div>
                          <div className="price">
                              <span>Price</span>
                              <div className="price-content"><img className="eth" src="assets/images/eth.svg" alt="eth"/><h3>{price}</h3></div>
                          </div>
                          <div className="remaining">
                              <span>Remaining</span>
                              <h3>{remaining}/{maxSupply}</h3>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%', backgroundColor: alertColor }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  </section>)
};

export default Shop;