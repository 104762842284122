import React, {Component} from 'react';
import p5 from 'p5';

if (typeof window !== "undefined") {
  window.p5 = p5;
}

const CANVAS_WIDTH_PX = 400;
const CANVAS_HEIGHT_PX = 400;
const SCALE = 4;

class Canvas extends Component {

    constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
    }

    componentDidMount = () => {
      this.sketch = new p5((p) => {
        
        // Variables
        let img;
        let paths = [];
        let currentPath = [];

        // Clear canvas
        const clearCanvas = () => {
          paths = [];
          currentPath = [];
          img.clear();
        }

        const arrayEquals = (a, b) => {
          return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
          );
        }

        const validCoordinates = (x, y) => {
            return ((x >= 0 && x < CANVAS_WIDTH_PX * SCALE) && (y >= 0 && y < CANVAS_HEIGHT_PX * SCALE));
        }

        const floodFill = (x, y, fillColor) => {
          if (!validCoordinates (x, y)) return;
          const seed = img.createVector(x, y);

          // Convert fill color to RGBA.
          fillColor = fillColor.replace('#', '');
          let bigint = parseInt(fillColor, 16);
          const r = (bigint >> 16) & 255;
          const g = (bigint >> 8) & 255;
          const b = bigint & 255;
          const fillColorRGBA = [r, g, b, 255]

          // Load current pixel data.
          img.loadPixels();
          paths = [];
          currentPath = [];

          let seedIndex = 4 * (img.width * seed.y + seed.x);

          let seedColor = [
            img.pixels[seedIndex],
            img.pixels[seedIndex + 1],
            img.pixels[seedIndex + 2],
            img.pixels[seedIndex + 3],
          ];

          let queue = [];
          queue.push(seed);

          while (queue.length > 0) {
            let current = queue.shift();

            if (!validCoordinates(current.x, current.y)) {
              continue;
            }

            let currentIndex = 4 * (img.width * current.y + current.x);
            let currentColor = [
              img.pixels[currentIndex],
              img.pixels[currentIndex + 1],
              img.pixels[currentIndex + 2],
              img.pixels[currentIndex + 3],
            ];

            if (arrayEquals(currentColor, fillColorRGBA)) {
              continue;
            }

            if (!arrayEquals(currentColor, seedColor)) {
              continue;
            }

            for (let i = 0; i < 4; i++) {
              img.pixels[currentIndex + i] = fillColorRGBA[0 + i];
            }

            queue.push(img.createVector(current.x - 1, current.y));
            queue.push(img.createVector(current.x + 1, current.y));
            queue.push(img.createVector(current.x, current.y - 1));
            queue.push(img.createVector(current.x, current.y + 1));
          }

          img.updatePixels();
        }

        // Setup
        p.setup = () => {
          p.createCanvas(CANVAS_WIDTH_PX, CANVAS_HEIGHT_PX).parent(this.canvasRef.current);
          p.pixelDensity(1);
          img = p.createGraphics(p.width * SCALE, p.height * SCALE);
          img.pixelDensity(1);
          p.background(255);
          p.stroke(0);
          p.noFill();
          this.props.getClearCanvas(clearCanvas);
        };

        // Draw
        p.draw = () => {
          p.background(255);
          p.noFill();
          img.noFill();
          // When drawing
          if(p.mouseIsPressed && this.props.tool !== 'fill') {
            const point = {
              x: p.mouseX * SCALE,
              y: p.mouseY * SCALE,
              type: this.props.tool,
              color: this.props.color,
              weight: this.props.stroke
            };
            currentPath.push(point);
          }

          paths.forEach(path => {
            img.beginShape();
            path.forEach(point => {
              if (point.type === 'brush') {
                img.noErase()
                img.stroke(point.color);
              } else if (point.type === 'eraser') {
                img.erase()
              } else {
                // do nothing
              }
              img.strokeCap(p.ROUND);
              img.strokeJoin(p.ROUND);
              img.strokeWeight(point.weight);
              img.vertex(point.x, point.y);
            });
            img.endShape();
          });

          // Render
          p.image(img, 0, 0, p.width, p.height);
          this.props.callback(img.elt.toDataURL('image/png', 1.0))
          if (this.props.tool !== 'fill') {
            // Set color
            p.fill(this.props.stroke);
            p.stroke(0);

            p.stroke(255, 0, 0, 75);
            p.fill(0, 0, 0, 0);
            p.ellipse(p.mouseX, p.mouseY, this.props.stroke / SCALE, this.props.stroke / SCALE);
          }
        }

        // Mouse Clicked
        p.mouseClicked = () => {
          if (this.props.tool === 'fill') {
            let mouseX = Math.trunc(p.mouseX * SCALE);
            let mouseY = Math.trunc(p.mouseY * SCALE);
            if (validCoordinates(mouseX, mouseY)) {
              floodFill(mouseX, mouseY, this.props.color);
            }
          } else if (this.props.tool === 'brush' || this.props.tool === 'eraser') {
            currentPath = [];
            paths.push(currentPath);
          } else {
            // do nothing.
          }
        }
      });
    }

    shouldComponentUpdate() {
      return false;
    }

    componentWillUnmount() {
      this.sketch.remove();
    }

    render() {
      return (<div ref={this.canvasRef} className="draw-main" />)
    }
}

export default Canvas;