import React, { useEffect, useState } from 'react'

import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { FixedSizeList as List } from 'react-window';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Canvas from './Canvas';
import StrokeSlider from './StrokeSlider';
import DialogButton from './DialogButton';
import HtmlTooltip from './HtmlTooltip';


import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'

import { useEagerConnect, useInactiveListener } from '../hooks'
import { injected } from '../connectors'
import JpegGallery from '../abis/JpegGallery.json';
import { PROJECT_ID } from '../config';

import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = initializeApp({
  projectId: PROJECT_ID
});

const functions = getFunctions(app);
const canvasCallable = httpsCallable(functions, 'canvas');
const submitCallable = httpsCallable(functions, 'submit');

const Create = () => {

  const context = useWeb3React()
  const { connector, library, chainId, account, activate, deactivate, active, error } = context

  // State
  const [tool, setTool] = useState('brush')
  const [color, setColor] = useState('#212121')
  const [stroke, setStroke] = useState(30)
  const [selectedCanvasIndex, setSelectedCanvasIndex] = useState(0)
  const [contract, setContract] = useState()
  const [paused, setPaused] = useState()
  const [balance, setBalance] = useState(0)
  const [wallet, setWallet] = useState([])
  const [name, setName] = useState('')
  const [canvasData, setCanvasData] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [clearCanvas, setClearCanvas] = useState()
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertColor, setAlertColor] = useState("#161d2e");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const abi = JpegGallery.abi

  useEffect(async () => {
    if (contract && account) {
      // Get balance
      const balance = await contract.balanceOf(account);
      setBalance(balance)
      // Get wallet
      await updateWallet();
    }
  }, [contract, account])

  useEffect(() => {
    getInfo()
}, [contract, paused])

const getInfo = async () => {
  if (contract) {
    const isPaused = await contract.paused()
    setPaused(isPaused)
  }
}

  useEffect(() => {
    let contract = null;
    if (chainId && library) {
        const address = JpegGallery.networks[chainId]?.address
        const provider = account ? library.getSigner(account).connectUnchecked() : library
        if (!address) {
          console.error(`Unsupported network (Chain ID: ${chainId})`)
        } else {
          contract = new Contract(address, abi, provider)
        }
    }
    setContract(contract)
  }, [account, library, chainId])

  const updateWallet = async () => {
    const wallet = await contract.walletOfOwner(account);
    const stringWallet = wallet.map((tokenId) => {return tokenId.toString()});
    const inventory = await getInventory(stringWallet);
    setWallet(inventory);
  }
  const sign = async (name) => {
    if (chainId && library && account) {
      const provider = library.getSigner(account)
      const address = JpegGallery.networks[chainId]?.address
      const flatSig = await provider._signTypedData(
        // Domain
        {
          name: 'The JPEG Gallery',
          version: '1.0.0',
          chainId: chainId,
          verifyingContract: address,
        },
        // Types
        {
          NFT: [
            { name: 'account', type: 'address' },
            { name: 'name', type: 'string' }
          ]
        },
        // Value
        { account, name }
      )
      return flatSig
    }
  }

  const getInventory = async (wallet) => {
    const result = await canvasCallable({ ids: wallet})
    if (result.data && result.data.inventory) {
      return result.data.inventory
    } else {
      return [];
    }
  }

  const submit = async (submissionName) => {
    const validation = validateTokenName(submissionName);
    if (!validation[0]) {
      setAlertColor("#fe3030");
      setAlertSeverity("error");
      setAlertMessage(validation[1]);
      setOpenAlert(true);
      return;
    }
    setSubmitting(true);
    setOpenBackdrop(true);
    let flatSig
    let result;
    let alertMessage = `Unable to submit Canvas #${wallet[selectedCanvasIndex]} to the gallery.`;
    let alertColor = "#fe3030";
    let alertSeverity = "error";
    try {
      flatSig = await sign(submissionName)
      try {
        result = await submitCallable({ auth: flatSig, imageData: canvasData, name: submissionName, tokenId: wallet[selectedCanvasIndex], account: account});
      } catch (e) {
        // Firebase Error
      }
      if (result && result.data) {
        let code = result.data.code;
        if (code === 200) {
          alertMessage = `Canvas #${wallet[selectedCanvasIndex]} has been submitted to the gallery.`;
          alertColor = "#161d2e";
          alertSeverity = "success";
          refreshInventory();
          clearCanvas();
          setName('');
        }
        if (code === 400) {
          if (result.data.message === 'Name already exists.') {
            alertMessage = `The name ${submissionName} has already been taken.`;
          }
          if (result.data.message === 'Invalid image.') {
            alertMessage = `The submitted image is invalid. Please submit a valid image.`;
          }
        }
      }
      setAlertColor(alertColor);
      setAlertSeverity(alertSeverity);
      setAlertMessage(alertMessage)
      setOpenAlert(true);
    } catch (e) {
      // Metamask error / User decline signing
    }
    setSubmitting(false);
    setOpenBackdrop(false);
  }

  const refreshInventory = () => {
    if (selectedCanvasIndex > -1) {
      wallet.splice(selectedCanvasIndex, 1);
    }
    setWallet(wallet)
    let newIndex = selectedCanvasIndex;
    while (newIndex >= wallet.length) {
      newIndex--;
    }
    setSelectedCanvasIndex(newIndex);
  }

  // Methods
  const selectTool = (e) => {
    e.preventDefault();
    setTool(e.target.id);
  }

  const selectColor = (e) => {
    e.preventDefault();
    setColor(e.target.id === 'color-one' ? 
      '#616161' : e.target.id === 'color-two' ?
      '#9e9e9e' : e.target.id === 'color-three' ?
      '#e0e0e0' : '#212121');
  }

  const selectStroke = (e, i) => {
    setStroke(i);
  }

  const selectCanvasIndex = (e, i) => {
    setSelectedCanvasIndex(i);
  }

  const updateCanvasImageData = (imageData) => {
    setCanvasData(imageData)
  }

  const getClearCanvas = (clearCanvas) => {
    setClearCanvas(() => clearCanvas)
  }

  const CanvasRow = ({ index, style }) => (
    <ListItem style={style} key={wallet[index]} component="div" disablePadding button divider selected={selectedCanvasIndex === index} onClick={(event) => selectCanvasIndex(event, index)}>
        <ListItemAvatar>
          <Avatar
            alt="JPEG Gallery Canvas"
            variant="rounded"
            sx={{ width: 50, height: 50, bgcolor: 'transparent'}}>
              <img className="shop-img" src="assets/images/canvas.png" alt="canvas" width="100" height="100"/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Blank Canvas" secondary={`#${wallet[index]}`} primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }}/>
    </ListItem>
  );

  const EmptyRow = ({ index, style }) => (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemText primary="Your inventory is empty. You can purchase a canvas from the shop." primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }} />
    </ListItem>
  );

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleCloseClearDialog = () => {
    setOpenClearDialog(false);
  };

  const handleConfirmOpenClearDialog = () => {
    clearCanvas();
    setOpenClearDialog(false);
  };

  const handleClickOpenClearDialog = () => {
    setOpenClearDialog(true);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const validateTokenName = (name) => {
    if (!name || (name && name.length < 1)) return [false, 'Name cannot be empty.']; // not empty
    if (name.length > 24) return [false, 'Name exceeds 24 characters.']; // max 24 characters
    const trimmedName = name.trim();
    if (trimmedName !== name) return [false, 'Name contains leading/trailing spaces.']; // leading / trailing whitespace
    let lastCode = name.charCodeAt(0)
    for (let i = 0; i < name.length; i++) {
      const code = name.charCodeAt(i);

      if (code == 32 && lastCode == 32) return [false, 'Name contains continuous spaces.']; // No continous spaces

      if (!(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code == 32)) // space
      { 
        return [false, 'Name contains non-alphanumeric characters.'];
      }
      lastCode = code;
    }
    return [true, ''];
  }


  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const activating =  activatingConnector === injected
  const connected = connector === injected
  const disabled = !triedEager || !!activatingConnector || connected || !!error

  return (
    <section id="draw-section">
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openBackdrop}
    >
    <CircularProgress color="inherit" sx={{ color: '#fe3030',}}/>
    </Backdrop>
    <Dialog
        open={openClearDialog}
        onClose={handleCloseClearDialog}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          Clear the canvas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clearing the canvas allows you to start fresh with a blank canvas. All current content will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogButton autoFocus onClick={handleCloseClearDialog}>
            Cancel
          </DialogButton>
          <DialogButton onClick={handleConfirmOpenClearDialog}>
            Confirm
          </DialogButton>
        </DialogActions>
      </Dialog>
    <div className="container-fluid">
      <div className="row create-row">

        {/* Inventory */}
        {(connected && account && !error) && (
          <div className="inventory-panel col-lg-2 col-md-2">
            <p className="draw-toolbar-section-title">Inventory</p>
            <Box
              className="inventory-box"
              sx={{
                maxHeight: 475
              }}>
                <List
                  className="inventory-list"
                  height={475}
                  itemSize={73.02}
                  itemCount={(wallet.length > 0) ? wallet.length : 1}
                  overscanCount={10}
                >
                  {(balance > 0 && wallet && wallet.length > 0 && chainId == 1) ? CanvasRow : EmptyRow}
                </List>
            </Box>
          </div>
        )}

        {/* Create */}
        <div className={(connected && account && !error)? "draw-panel col-lg-6 col-md-6 offset-lg-1 offset-md-1 section-head text-center" : "draw-panel col-lg-6 offset-lg-3 col-md-6 offset-md-2 section-head text-center"}>
          <div className="draw-container">
            <div className="title-header">
              <div className="draw-title">Create</div>
              <HtmlTooltip title={
          <React.Fragment>
            <b>Create a JPEG submission.</b>
            <br></br>
            <br></br>
            <b>Naming Guidelines:</b>
            <ul>
              <li><em>24 character limit.</em></li>
              <li><em>Alphanumeric characters only.</em></li>
              <li><em>No leading/trailing/continuous spaces.</em></li>
            </ul>
          </React.Fragment>
        } placement="bottom" arrow>
                <div>
                    <i className="far fa-question-circle create-info"></i>
                </div>
              </HtmlTooltip>
              
            </div>
              {(connected && account && !error) && (
                <div className="draw-container-content">
                  <Canvas tool={tool} color={color} stroke={stroke} callback={updateCanvasImageData} getClearCanvas={getClearCanvas} />
                </div>
              )}
              {(connected && account && !error) && (
                <div className="submit-action-content">
                  <input disabled={balance <= 0 || submitting || chainId !== 1} className="submit-name" placeholder="Name" value={name} onChange={e => setName(e.target.value)}></input>
                  {(balance <= 0 || submitting || chainId !== 1) && (
                    <Tooltip title={submitting ? "Your JPEG is being submitted to the gallery." : "You must have a canvas to submit work to the gallery."} placement="bottom" arrow>
                      <span>
                        <button disabled={true} style={disabled ? { pointerEvents: 'none' } : {}} className="btn-main">Submit</button>
                      </span>
                    </Tooltip>
                  )}
                  {(balance > 0 && !submitting && chainId === 1) && (
                    <Tooltip title="Submit to the gallery." placement="bottom" arrow>
                      <span>
                        <button className="btn-main" onClick={connected ? ()=>{submit(name)} : ()=>{}}>Submit</button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}
              {!(connected && account && !error) && (
                <div className="create-connect">
                  <button className="btn-main" onClick={!connected ? () => {
                        setActivatingConnector(injected)
                        activate(injected)
                    }: error ? () => {
                        deactivate(injected)
                    } : ()=>{}}>Connect Wallet
                  </button>
              </div>
              )}
          </div>
        </div>

        {/* Tools */}
        {(connected && account && !error) && (
          <div className="tools-panel col-lg-2 col-md-2 offset-lg-1 offset-md-1">
            <div className="draw-toolbar">
              <div className="draw-toolbar-section">
                <p className="draw-toolbar-section-title">Tools</p>
                <div className="draw-toolbar-section-tools">
                  <Tooltip title="Brush" placement="top" arrow>
                    <span id="brush" className={tool === "brush" ? "btn-toolbar-active btn-toolbar btn-tool" : "btn-toolbar btn-tool"} onClick={selectTool}>
                      <i className="fas fa-paint-brush" aria-hidden="true"></i>
                    </span>
                  </Tooltip>
                  <Tooltip title="Eraser" placement="top" arrow>
                    <span id="eraser" className={tool === "eraser" ? "btn-toolbar-active btn-toolbar btn-tool" : "btn-toolbar btn-tool"} onClick={selectTool}>
                      <i className="fas fa-eraser" aria-hidden="true"></i>
                    </span>
                  </Tooltip>
                  <Tooltip title="Fill" placement="bottom" arrow>
                    <span id="fill" className={tool === "fill" ? "btn-toolbar-active btn-toolbar btn-tool" : "btn-toolbar btn-tool"} onClick={selectTool}>
                      <i className="fas fa-fill-drip" aria-hidden="true"></i>
                    </span>
                  </Tooltip>
                  <Tooltip title="Trash" placement="bottom" arrow>
                    <span id="trash" className={tool === "trash" ? "btn-toolbar-active btn-toolbar btn-tool" : "btn-toolbar btn-tool"} onClick={handleClickOpenClearDialog}>
                      <i className="fas fa-trash" aria-hidden="true"></i>
                    </span>
                  </Tooltip>
                </div>
              </div>
                <div className="draw-toolbar-section">
                  <p className="draw-toolbar-section-title stroke-section-title">Stroke</p>
                  <div className="draw-toolbar-section-tools-stroke">
                    <StrokeSlider defaultValue={stroke} value={stroke} onChange={selectStroke} aria-labelledby="slider" min={5} max={200} className='slider-toolbar' />
                  </div>
                </div>
              <Grow in={tool === 'brush' || tool === 'fill'}>
                <div className="draw-toolbar-section color-section">
                  <div className="color-section-title">
                  <p className="draw-toolbar-section-title colors-title">Colors</p>
                  <HtmlTooltip title={
                    <React.Fragment>
                      <b>Use grayscale colors to create a JPEG.</b>
                      <br></br>
                      <br></br>
                      <em>Note: Upon submission to the gallery, a randomly generated color palette will be assigned to your JPEG, replacing the grayscale colors used.</em>
                    </React.Fragment>
                  } placement="left" arrow>
                      <div>
                        <i className="far fa-question-circle colors-info"></i>
                      </div>
                    </HtmlTooltip>
                  </div>
                  
                  <div className="draw-toolbar-section-tools">
                  <Grow in={tool === 'brush' || tool === 'fill'} style={{transformOrigin: '0 0 0'}} {...(tool === 'brush' || tool === 'fill' ? { timeout: 1000 } : {})}>
                    <Tooltip title="Color #1" placement="top" arrow>
                      <button id="color-black" className={color === "#212121" ? "btn-toolbar-active btn-toolbar btn-color btn-black" : "btn-toolbar btn-color btn-black"} onClick={selectColor}></button>
                    </Tooltip>
                  </Grow>
                  <Grow in={tool === 'brush' || tool === 'fill'} style={{transformOrigin: '0 0 0'}} {...(tool === 'brush' || tool === 'fill' ? { timeout: 2000 } : {})}>
                    <Tooltip title="Color #2" placement="top" arrow>
                      <button id="color-one" className={color === "#616161" ? "btn-toolbar-active btn-toolbar btn-color btn-one" : "btn-toolbar btn-color btn-one"} onClick={selectColor}></button>
                    </Tooltip>
                  </Grow>
                  <Grow in={tool === 'brush' || tool === 'fill'} style={{transformOrigin: '0 0 0'}} {...(tool === 'brush' || tool === 'fill'? { timeout: 3000 } : {})}>
                    <Tooltip title="Color #3" placement="bottom" arrow>
                      <button id="color-two" className={color === "#9e9e9e" ? "btn-toolbar-active btn-toolbar btn-color btn-two" : "btn-toolbar btn-color btn-two"} onClick={selectColor}></button>
                    </Tooltip>
                  </Grow>
                  <Grow in={tool === 'brush' || tool === 'fill'} style={{transformOrigin: '0 0 0'}} {...(tool === 'brush' || tool === 'fill' ? { timeout: 4000 } : {})}>
                    <Tooltip title="Color #4" placement="bottom" arrow>
                      <button id="color-three" className={color === "#e0e0e0" ? "btn-toolbar-active btn-toolbar btn-color btn-three" : "btn-toolbar btn-color btn-three"} onClick={selectColor}></button>
                    </Tooltip>
                  </Grow>
                  </div>
                </div>
                </Grow>
            </div>
          </div>
        )}
      </div>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%', backgroundColor: alertColor }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  </section>
  )
}

export default Create;