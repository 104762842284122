import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'

import { Web3Provider } from '@ethersproject/providers'

import Header from './Header';
import Main from './Main';
import Footer from './Footer';

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  return library
}

const App = () => {
  return (
  <div>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Header />
      <Main />
      <Footer />
    </Web3ReactProvider>
  </div>
  )
}

export default App;