import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const DialogButton = styled(Button)(() => ({
    background: "#fe3030",
    '&:hover, &.Mui-focusVisible': {
        background: "#fe3030",
    },
    borderRadius: "15px",
    fontSize: "17px",
    border: "2px solid #fe3030",
    color: "#fefefe",
    padding: "7px 7px",
    textDecoration: "none",
    fontWeight: "500",
    textTransform: "capitalize",
  }));

export default DialogButton;