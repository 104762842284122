import React, {useEffect, useState} from 'react'

const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600*24));
    const h = Math.floor(seconds % (3600*24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);
    
    const dDisplay = d > 0 ? d < 10 ? `0${d} : ` : `${d} : ` : '';
    const hDisplay = h > 0 ? h < 10 ? `0${h} : ` : `${h} : ` : d > 0 ? `00 : ` : '';
    const mDisplay = m > 0 ? m < 10 ? `0${m} : ` : `${m} : ` : h > 0 ? `00 : ` : '';
    const sDisplay = s > 0 ? s < 10 ? `0${s}` : s : m > 0 ? '00' : '';
    return (dDisplay + hDisplay + mDisplay + sDisplay).replace(/,\s*$/, "");
}

const Timer = ({seconds}) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    
  
    useEffect(() => {
      // exit early when we reach 0
      if (!timeLeft) return;
  
      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
  
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft]);
  
    return (
      <div className="timer">
        {timeLeft >= 0 && <b>{secondsToDhms(timeLeft)}</b>}
      </div>
    );
  };

export default Timer;