import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import { useEagerConnect, useInactiveListener } from '../hooks'
import { injected } from '../connectors'

// The Header creates links that can be used to navigate
// between routes.
const Header = () => {
    const context = useWeb3React()
    const { connector, chainId, account, activate, deactivate, error } = context
  
    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState()
    useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined)
      }
    }, [activatingConnector, connector])
  
    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect()
  
    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector)

    const activating =  activatingConnector === injected
    const connected = connector === injected
    const disabled = !triedEager || !!activatingConnector || connected || !!error

    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img className="logo" src="assets/images/logo.svg" alt="logo" height="40" /> <img className="icon" src="assets/images/icon.svg" alt="logo" height="40" /></a>
                        <button className="navbar-toggler pull-left float-left" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons-round">
                                dehaze
                            </i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <NavLink exact className="nav-link" activeClassName="nav-link-active" to='/'>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="nav-link-active" to='/shop'>Shop</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="nav-link-active" to='/create'>Create</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="nav-link-active" to='/faq'>FAQ</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="nav-link-active" to='/docs' target="_blank">Docs</NavLink>
                                </li>
                            </ul>
                        </div>
                        <a href="# " className='btn-main btn-wallet'
                            onClick={!connected ? () => {
                                setActivatingConnector(injected)
                                activate(injected)
                            }: error ? () => {
                                deactivate(injected)
                            } : ()=>{}}>
                            <span className={
                                activating ? 'status-activated' :
                                connected && account && !error ? 'status-connected' : 
                                disabled ? 'status-disabled' : ''
                            }></span>
                            {
                                activating ? 'Connecting' :
                                connected && account && !error ?
                                    account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Connect Wallet'
                                : disabled ? 'Disconnected' : 'Connect Wallet'
                            }
                            </a>
                    </div>
                </nav>
                {(connected && account && chainId !== 1) && (
                <div className="banner">
                    <p>Your wallet is connected to {
                    chainId !== 1 ? 
                    chainId === 3 ? 'the Ropsten Test Network' : 
                    chainId === 4 ? 'the Rinkeby Test Network' : 
                    chainId === 5 ? 'the Goerli Test Network' : 
                    chainId === 42 ? 'the Kovan Test Network' :
                    'a test network' : 'a test network'
                    }. To use this site, please switch to the Ethereum Mainnet.</p>
                </div>
                )}
        </header>
    )
}

export default Header