import React from 'react';

const About = () => (
    <section id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 section-head text-center">
                    <div className="section-head-tag">How does it work?</div>
                    <h2 className="section-head-title">Create a Masterpiece</h2>
                    <p className="section-head-body">A bored primate? A cool feline? A fat flightless bird? The creatures you can doodle are endless.</p>
                </div>
            </div>
            <div className="row about-body">

                <div className="col-lg-4 col-md-6 mb-4 about-body-item d-flex align-items-center">
                    <div>
                        <div className="about-body-icon-cnt">
                        <i className="material-icons-round about-body-icon">
                            {/* grain */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 297 297" id="mint-svg">
                                <path d="M247.5,188H241V43h6.5c5.523,0,10-4.478,10-10s-4.477-10-10-10h-89V10c0-5.523-4.477-10-10-10s-10,4.477-10,10v13h-89
                                    c-5.522,0-10,4.478-10,10s4.478,10,10,10H56v145h-6.5c-5.522,0-10,4.477-10,10s4.478,10,10,10h53.829l-30.114,75.283
                                    c-2.051,5.128,0.443,10.947,5.571,12.999c1.218,0.487,2.475,0.718,3.711,0.718c3.969,0,7.724-2.379,9.288-6.289l33.001-82.5
                                    c0.027-0.07,0.046-0.141,0.072-0.211H138.5v78.997c0,5.522,4.477,10,10,10s10-4.478,10-10V208h13.642
                                    c0.026,0.07,0.045,0.141,0.072,0.211l33.001,82.5c1.564,3.91,5.319,6.289,9.288,6.289c1.236,0,2.493-0.231,3.711-0.718
                                    c5.128-2.052,7.622-7.871,5.571-12.999L193.671,208H247.5c5.523,0,10-4.477,10-10S253.023,188,247.5,188z M76,43h145v145H76V43z" fill="#fefefe"/>
                            </svg>
                        </i>
                        </div>
                    </div>
                    <div>
                        <div className="about-body-text-head">Mint a Canvas</div>
                        <div className="about-body-text-main">
                            <p>
                                Reserve a spot in the gallery by purchasing a canvas from the shop.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4 about-body-item d-flex align-items-center">
                    <div>
                        <div className="about-body-icon-cnt">
                            <i className="material-icons-round about-body-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 325.04 325.04" id="create-svg">
                            <g>
                                <path d="M117.866,234.088c-2.956,14.532-4.875,21.558-16.092,22.458c-2.764,0.222-5.015,2.308-5.446,5.047
                                    c-0.432,2.738,1.069,5.416,3.631,6.477c0.721,0.298,17.877,7.308,37.921,7.309c0.003,0,0.005,0,0.007,0
                                    c13.968,0,25.95-3.386,35.612-10.063c11.45-7.912,19.344-20.294,23.541-36.788l-38.572-38.88
                                    C125.871,194.924,121.253,217.436,117.866,234.088z" fill="#fefefe"/>
                                <path d="M322.745,63.336c-1.037-1.046-2.887-2.293-5.806-2.293c-3.423,0-12.516,0-67.74,46.992
                                    c-25.509,21.706-54.92,48.559-78.314,71.41l36.603,36.894c24.061-25.009,52.129-56.355,74.451-83.258
                                    c14.096-16.986,24.935-31.002,32.216-41.657C323.799,77.311,328.023,68.655,322.745,63.336z" fill="#fefefe"/>
                                <path d="M182.595,278.479c-12.387,8.56-27.429,12.899-44.716,12.899c-22.753-0.001-41.919-7.649-44.046-8.527
                                    c-9.425-3.906-14.898-13.673-13.31-23.749c1.555-9.871,9.463-17.373,19.341-18.446c0.861-2.571,1.813-7.254,2.323-9.758
                                    c1.878-9.23,4.449-21.873,12.358-33.126c8.637-12.287,21.656-20.165,38.751-23.466c9.811-9.737,21.005-20.443,32.686-31.308
                                    c-5.905-1.281-11.185-5.127-14.017-10.944c-4.875-10.02-0.623-22.073,9.484-26.895c10.133-4.834,22.287-0.612,27.155,9.423
                                    c0.961,1.978,1.555,4.033,1.832,6.096c9.688-8.677,19.309-17.099,28.392-24.828c0.054-0.046,0.105-0.09,0.16-0.136
                                    c-10.209-19.536-24.849-36.845-42.687-50.098c-25.614-19.031-56.114-29.096-88.2-29.104c-0.01,0-0.017,0-0.025,0
                                    c-21.654,0-47.976,7.566-68.697,19.749C13.981,51.193-0.005,71.163,0,92.49c0.008,25.748,14.53,36.518,26.199,45.171
                                    c9.515,7.057,17.03,12.63,17.034,24.844c0.003,12.213-7.508,17.781-17.018,24.831c-11.665,8.648-26.184,19.412-26.176,45.163
                                    c0.006,21.324,14.001,41.299,39.406,56.244c20.736,12.198,47.072,19.78,68.73,19.786c0.015,0,0.028,0,0.042,0
                                    c39.305,0,76.254-15.171,104.044-42.72c20.837-20.655,34.656-46.416,40.273-74.442c-13.952,15.471-27.997,30.493-40.563,43.322
                                    C206.641,253.965,196.773,268.682,182.595,278.479z M111.054,77.103c2.498-10.871,13.4-17.657,24.354-15.167
                                    c10.939,2.478,17.793,13.282,15.313,24.138c-2.499,10.844-13.407,17.631-24.362,15.154
                                    C115.411,98.764,108.554,87.947,111.054,77.103z M45.054,114.152c-7.005-8.716-5.565-21.401,3.216-28.339
                                    c8.78-6.925,21.571-5.505,28.589,3.195c6.99,8.703,5.545,21.388-3.229,28.34C64.869,124.288,52.058,122.853,45.054,114.152z
                                    M55.746,247.168c-8.786-6.944-10.231-19.629-3.226-28.342c7-8.696,19.796-10.122,28.581-3.18
                                    c8.778,6.943,10.224,19.629,3.225,28.327C77.327,252.686,64.53,254.111,55.746,247.168z" fill="#fefefe"/>
                            </g>
                            </svg>
                            </i>
                        </div>
                    </div>
                    <div>
                        <div className="about-body-text-head">Create a JPEG</div>
                        <div className="about-body-text-main">
                            <p>
                                Create an image from the Create tab and give your work of art a unique name.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4 about-body-item d-flex align-items-center">
                    <div>
                        <div className="about-body-icon-cnt">
                            <i className="material-icons-round about-body-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 367.043 367.043" id="submit-svg">
                                <g>
                                    <path d="M344.041,105.519h-57.267l-83.372-83.372c0.076-0.706,0.119-1.421,0.119-2.147c0-11.045-8.954-20-20-20s-20,8.955-20,20
                                        c0,0.726,0.043,1.441,0.119,2.147l-83.372,83.372H23.001c-5.236,0-9.48,4.246-9.48,9.48v242.563c0,5.236,4.244,9.48,9.48,9.48
                                        h321.04c5.236,0,9.48-4.244,9.48-9.48V115C353.521,109.765,349.277,105.519,344.041,105.519z M175.674,38.398
                                        c2.41,1.029,5.061,1.602,7.847,1.602s5.438-0.572,7.848-1.602l67.12,67.121H108.554L175.674,38.398z M314.654,316.662
                                        c0,5.236-4.244,9.48-9.479,9.48H61.869c-5.237,0-9.48-4.244-9.48-9.48v-160.76c0-5.236,4.244-9.48,9.48-9.48h243.306
                                        c5.236,0,9.479,4.244,9.479,9.48V316.662L314.654,316.662z" fill="#fefefe"/>
                                    <path d="M243.716,179.767c-1.009,0.859-2.004,1.724-2.996,2.593c0,0.001-0.001,0.001-0.001,0.001
                                        c-10.124,8.739-20.562,17.448-31.581,25.497c-1.918-3.426-6.56-5.084-10.88-2.172c-0.129,0.088-2.102,1.432-2.775,1.836
                                        c-1.836,1.104-3.766,2.056-5.691,2.987c-0.187,0.091-0.377,0.175-0.565,0.263c3.487-3.744,6.978-7.488,10.466-11.234
                                        c5.084-5.456-1.447-14.574-8.105-10.504c-5.34,3.265-10.849,6.271-16.41,9.145c-8.272,4.27-18.732,9.704-27.478,10.454
                                        c-8.527,0.729-8.599,14.112,0,13.376c5.713-0.49,11.268-2.07,16.659-4.15c-1.48,1.59-2.963,3.181-4.445,4.771
                                        c-3.688,3.959-1.234,10.771,3.639,11.459c-14.147,5.81-29.403,9.874-46.143,11.345c-4.423,0.387-7.695,4.291-7.307,8.715
                                        c0.367,4.186,3.879,7.34,8.002,7.34c0.235,0,0.474-0.012,0.714-0.031c8.195-0.721,16.25-2.014,24.21-3.885
                                        c-14.797,10.369-26.087,18.336-26.335,18.512c-3.466,2.445-4.435,7.164-2.215,10.777c2.22,3.617,6.867,4.885,10.615,2.9
                                        l85.481-45.254c-7.37,9.123-14.497,18.564-18.94,25.967c-1.715,2.862-1.486,6.488,0.578,9.111
                                        c2.062,2.623,5.531,3.697,8.718,2.702c3.101-0.97,6.559-2.495,10.087-4.296l-2.645,4.808c-1.469,2.67-1.302,5.94,0.432,8.449
                                        c1.515,2.19,3.998,3.469,6.613,3.469c0.377,0,0.758-0.026,1.139-0.081c0.71-0.102,17.49-2.513,26.712-5.027
                                        c4.285-1.168,6.812-5.59,5.642-9.875c-1.167-4.285-5.588-6.812-9.874-5.643c-2.379,0.648-5.43,1.305-8.571,1.911l9.693-17.618
                                        c1.821-3.314,1.09-7.447-1.757-9.934c-2.728-2.383-6.694-2.637-9.696-0.672c11.101-13.312,21.417-24.637,21.604-24.84
                                        c2.636-2.889,2.814-7.254,0.42-10.347c-2.396-3.092-6.666-4.012-10.121-2.185l-13.314,7.049c2.922-2.648,5.412-5.172,8.124-7.914
                                        c3.979-4.029,8.487-8.594,15.852-15.038c0.984-0.849,1.962-1.696,2.932-2.536c3.346-2.898,3.72-7.959,0.833-11.318
                                        C252.145,177.289,247.087,176.896,243.716,179.767z" fill="#fefefe"/>
                                </g>
                            </svg>
                            </i>
                        </div>
                    </div>
                    <div>
                        <div className="about-body-text-head">Submit to Gallery</div>
                        <div className="about-body-text-main">
                            <p>
                                Submit your JPEG to the most iconic art collection in the Metaverse.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default About;
